.nav-pointer {
  cursor: pointer;
}

.dashboard-heading {
  font-size: 20px !important;
  color: white;
  font-weight: bold;
  padding-top: 12px;
}

.dashboard-column-heading {
  font-size: 14px !important;
}

.dashboard-data {
  padding: 6px !important;
}

.dashboard-item {
  font-size: 13px;
}
