@import url("https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");

.ck-editor__editable_inline,
.ck-editor {
  min-height: 500px;
  width: 100% !important;
  overflow-wrap: break-word;
}

.note-body {
  font-family: "PT Sans", sans-serif;
  line-height: 1.3em;
}
