@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;900");

.pointer {
  cursor: pointer;
}

.main-header__brand img {
  /* height: 100%; */
  margin: auto;
  padding-left: 2rem;
  width: 300px;
}

.brand-login {
  margin: auto;
  width: 300px;
}

.main-header {
  width: 100%;
  position: static;
  top: 0;
  left: 0;
  background: #343a40;
  padding: 0.5rem 1rem;
  z-index: 1;
}

.main-header > div {
  display: inline-block;
  vertical-align: middle;
}

.toggle-button {
  width: 3rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding-top: 0;
  padding-bottom: 0;
  vertical-align: middle;
}

.toggle-button:focus {
  outline: none;
}

.toggle-button__bar {
  width: 100%;
  height: 0.2rem;
  background: white;
  display: block;
  margin: 0.6rem 0;
}

.main-nav {
  display: none;
}

.main-nav__items {
  margin: 0;
  padding: 0;
  list-style: none;
}

.main-nav__item {
  display: inline-block;
  margin: 0 1rem;
  color: white;
  cursor: pointer;
  font-weight: bold;
}

.main-nav__item a,
.mobile-nav__item a {
  text-decoration: none;
  color: #ffffff;
  font-weight: bold;
  padding: 0.2rem 0;
}

.main-nav__item:hover,
.main-nav__item:active {
  color: #ffc107;
  border-bottom: 2px solid black;
}

.main-nav__item--cta a,
.mobile-nav__item--cta a {
  color: white;
  background: #ff1b68;
  padding: 0.5rem 1rem;
  border-radius: 8px;
}

.main-nav__item--cta a:hover,
.main-nav__item--cta a:active,
.mobile-nav__item--cta a:hover,
.mobile-nav__item--cta a:active {
  color: #ff1b68;
  background: white;
  border: none;
}

.mobile-nav {
  display: none;
  position: static;
  z-index: 101;
  top: 0;
  left: 0;
  background: white;
  width: 80%;
  height: 100vh;
  padding: 0.5rem;
}

.mobile-nav__items {
  width: 90%;
  height: 100%;
  list-style: none;
  margin: 10% auto;
  padding: 0;
  text-align: center;
}

.mobile-nav__item {
  margin-top: 10px;
  background: #ffc107;
  border: none;
  border-radius: 10px;
  color: black;
  height: 60px;
  font-weight: bold;
  vertical-align: middle;
  padding: 20px 0px 20px 0px;
}

.mobile-nav-userButton {
  margin: 5px;
  background: #ffc107;
  color: black;
  height: 40px;
  font-weight: bold;
  vertical-align: middle;
  padding: 10px 0px;
}

.mobile-nav__item a {
  font-size: 1.5rem;
}

.main-nav__logout {
  display: none;
}
.main-nav__admin_links {
  display: none;
}

.open {
  display: block !important;
}

.hide {
  display: none !important;
}
@media (min-width: 46rem) {
  /* min 736 Pixels */
  .summary__badges {
    width: 100%;
  }
  .summary-badges {
    width: 150px;
    height: 40px;
    margin-left: 10px;
  }
}

/* ! LARGE SCREENS - NAVIGATION */
@media (min-width: 86rem) {
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .main-header__brand {
    order: 1;
  }
  .main-header__brand img {
    /* height: 100%; */
    width: 250px;

    /* margin: auto; */
  }

  .toggle-button {
    display: none;
  }

  .main-nav {
    margin-top: 25px;
    margin-left: 20px;
    display: inline-block;
    text-align: left;
    width: 100%;
    vertical-align: middle;
    order: 2;
    font-size: 1.1rem;
    font-weight: 400;
  }

  .main-nav__logout {
    display: block !important;
    font-weight: 400;
    cursor: pointer;
    padding: 9px 25px;
    border: none;
    border-radius: 5px;
    background-color: #ffc107;
    transition: all 0.3s ease 0s;
    margin: 0px 2px;
    order: 5;
  }

  .main-nav__logout:hover {
    background-color: #6c757d;
    color: white;
    font-weight: 700;
  }

  .main-nav__admin_links {
    display: block !important;
    font-weight: 400;
    cursor: pointer;
    padding: 9px 25px;
    border: none;
    border-radius: 5px;
    background-color: #ffc107;
    transition: all 0.3s ease 0s;
    margin: 0px 4px;
    order: 4;
  }

  .main-nav__admin_links:hover {
    background-color: #6c757d;
    color: white;
    font-weight: 700;
  }

  .navbar-toggler {
    display: none;
  }

  /* Dropdown content (hidden by default) */
  .dropdown-content {
    font-size: 13px;
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  /* Links inside the dropdown */
  .dropdown-content p {
    float: none;
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
    background-color: #343a40;
    margin-bottom: 0%;
  }

  /* Add a grey background color to dropdown links on hover */
  .dropdown-content p:hover {
    background-color: #ddd;
    color: black;
  }

  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }

  /*! EXTRA LARGE SCREENS - NAVIGATION */
  @media (min-width: 116rem) {
    * {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
    }

    header {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .main-header__brand {
      order: 1;
    }
    .main-header__brand img {
      /* height: 100%; */
      width: 250px;

      /* margin: auto; */
    }

    .toggle-button {
      display: none;
    }

    .main-nav {
      margin-top: 25px;
      margin-left: 20px;
      display: inline-block;
      text-align: left;
      width: 100%;
      vertical-align: middle;
      order: 2;
      font-size: 1.1rem;
      font-weight: 400;
    }

    .main-nav__logout {
      display: block;
      font-weight: 400;
      cursor: pointer;
      padding: 9px 25px;
      border: none;
      border-radius: 5px;
      background-color: #ffc107;
      transition: all 0.3s ease 0s;
      margin: 0px 2px;
      order: 5;
    }

    .main-nav__logout:hover {
      background-color: #6c757d;
      color: white;
      font-weight: 700;
    }

    .main-nav__admin_links {
      display: block;
      font-weight: 400;
      cursor: pointer;
      padding: 9px 25px;
      border: none;
      border-radius: 5px;
      background-color: #ffc107;
      transition: all 0.3s ease 0s;
      margin: 0px 4px;
      order: 4;
    }

    .main-nav__admin_links:hover {
      background-color: #6c757d;
      color: white;
      font-weight: 700;
    }

    .navbar-toggler {
      display: none;
    }
  }
}
