.login-background {
  margin: 0;
  height: 70vh;
  max-width: 1920px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ecf0f3;
}

.login-div {
  width: 530px;
  height: 500px;
  padding: 60px 35px 35px 35px;
  border-radius: 40px;
  background: #ecf0f3;
  box-shadow: 13px 13px 20px #cbced1, -13px -13px 20px #ffffff;
}

.forgot_password a {
  text-align: right;
  font-weight: 400;
  font-size: 14px;
  color: #2299f3;
}

.fields {
  width: 100%;
  padding: 45px 5px 5px 5px;
}

.fields svg {
  height: 22px;
  margin: 0 10px -3px 25px;
}

.fields input {
  width: 80%;
  border: none;
  outline: none;
  background: none;
  font-size: 17px;
  color: #555;
  padding: 20px 10px 20px 5px;
}

.username,
.password {
  margin-bottom: 30px;
  border-radius: 25px;
  box-shadow: inset 8px 8px 8px #cbced1, inset -8px -8px 8px #ffffff;
}

.title {
  text-align: center;
  font-size: 38px;
  font-weight: 700;
  padding-top: 10px;
}

.signin-button {
  outline: none;
  border: none;
  cursor: pointer;
  width: 100%;
  height: 60px;
  border-radius: 30px;
  font-size: 20px;
  font-weight: 700;
  font-family: "Lato", sans-serif;
  color: #fff;
  text-align: center;
  background: rgb(31, 29, 29);
  box-shadow: 3px 3px 8px #b1b1b1, -3px -3px 8px #ffffff;
  transition: 0.5s;
}

.signin-button:hover {
  background: rgb(201, 201, 201);
  color: #000000;
}

.signin-button:active {
  background: #2fdbb6;
}

.link {
  width: 100%;
  padding-top: 20px;
  text-align: right;
  padding-right: 20px;
}

.link a {
  text-decoration: none;
  color: #aaa;
  font-size: 15px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #ecf0f3 inset !important;
}

@media (max-width: 499px) {
  .login-div {
    width: 330px;
    height: 375px;
    padding: 10px 25px 25px 25px;
  }

  .signin-button {
    height: 50px;
    border-radius: 30px;
    font-size: 18px;
  }

  .link {
    padding-top: 10px;
  }

  .link a {
    font-size: 12px;
  }

  .fields {
    width: 100%;
    padding: 25px 0px 3px 0px;
  }

  .fields input {
    width: 70%;
    font-size: 14px;
    padding: 15px 15px 15px 5px;
  }
}
